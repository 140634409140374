import styles from './index.module.css';

function Home({}) {
  return (
    <section id="home" className="py-100 px-0 flex items-center min-h-screen">
      <div className="px-12 m-auto w-full">
        <div className="w-full">
          {/* Main Title */}
          <div className="w-full">
            <h3 className="flex content-center justify-center text-5xl font-normal tracking-tight pb-15 mb-34 relative">Gary and Julie Johnson Family Reunion 2023</h3>
            <hr className="border-0 border-b-2 border-gray-200 mb-24" />
            <p className="flex content-center justify-center text-2xl leading-10 tracking-tight mb-57">
              The 10th (or 12th or 25th, who really knows) annual family reunion of the Gary and Julie Johnson Family.
            </p>
            <p className="flex content-center justify-center text-2xl leading-10 tracking-tight mb-57">
              Lots of games and activities are planned, for all ages.  We will have a great time together, and we hope you can join us!
            </p>
          </div>
          {/* /Main Title */}

          {/* Games */}
          <div className="flex content-center justify-center items-center w-full mt-[60px]">
            <div className="w-[60%] flex text-2xl justify-center content-center items-center text-blue-900 hover:text-blue-500 bg-white border border-black mx-[10%] p-4 rounded-lg">
              <a href="/memegame">➡ Games</a>
            </div>
          </div>
          {/* /Games */}
        </div>
      </div>
    </section>
  );
}

export async function getServerSideProps(context) {
  const nodeEnv = process.env.NEXT_PUBLIC_ENVIRONMENT;

  switch (nodeEnv) {
    case 'production':
    case 'local':
    case 'develop':
      return {
        redirect: {
          permanent: false,
          destination: '/',
        },
      };
    default:
      return { props: {} };
  }
  return { props: {} };
}

export default Home;

Home.title = 'Homepage';
